<template>
  <div class="flex items-center mt-2">
    <div class="flex items-center w-56 select-none text-sm leading-tight">
      {{ filter.label }}
    </div>
    <el-input
      v-model="textData"
      clearable
      autocorrect="off"
      autocapitalize="off"
      autocomplete="nope"
      spellcheck="false"
      :disabled="disabled"
      :placeholder="filter.placeholder"
      maxlength="7"
      @clear="handleFilterInput"
      @update:modelValue="handleFilterInput"
    />
  </div>
</template>

<script>
import typeOf from 'just-typeof'

export default {
  props: {
    filter: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      textData: this.modelValue || null,
      previousValue: null
    }
  },
  computed: {
    disabled() {
      const disable = this.filter && this.filter.disable
      if (disable && typeOf(disable) === 'function') {
        if (this.textData) {
          this.setToNull()
        }
      }
      return false
    }
  },
  methods: {
    handleFilterInput(v) {
      let value = this.textData || ''
      // Remove non-digit characters
      value = value.replace(/\D/g, '')

      // Validate and format the month
      if (value.length > 2) {
        let month = value.slice(0, 2)
        month = month <= 12 ? month : '12'
        value = month + '/' + value.slice(2)
      } else if (value.length === 2) {
        let month = value <= 12 ? value : '12'
        value = month + '/'
      }

      this.textData = value.length > 7 ? value.slice(0, 7) : value

      this.$emit('update:modelValue', this.textData)
    },
    setToNull() {
      this.$emit('update:modelValue', null)
      this.textData = null
    }
  }
}
</script>
